<template>
    <div class="avatar" :style="{backgroundImage: `url(${options.img})`, ...options.style}"></div>
</template>

<script>
export default {
    props: {
        options: Object
    }
}
</script>

<style lang="scss" scoped>
.avatar {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    border-radius: 5px;
}
</style>