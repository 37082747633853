<template>
    <div class="vue-passengers">
        <p>{{index+1}} - пассажир</p>
        <div class="table_header">
            <div>
                <p>Автобус</p>
                <p>
                    <img src="@/assets/images/ui/blue_chair.svg" alt="">
                    № {{item.number}}
                </p>
            </div>
            <div>
                <p>Тип</p>
                <p>{{item.type=='sleeping' ? 'Спальный' : "Сидячий"}}</p>
            </div>
            <div>
                <p>Место</p>
                <p>{{item.seat}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
        index: Number
    }
}
</script>

<style lang="scss" scoped>
.vue-passengers {
    border-bottom: 1px solid #E9E9E9;
    padding: 20px 0px;
}
.table_header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: space-between;
    div {
        &:first-of-type {
            p {
                &:first-of-type {
                    margin-left: 28px;
                }
            }
        }
        p {
            margin-bottom: 0;
            &:first-of-type {
                font-size: 12px;
                line-height: 14px;
                color: #FF8413;
            }
            &:last-of-type {
                font-size: 16px;
                line-height: 19px;
                color: #021011;
                margin-top: 5px;
                display: flex;
                align-items: center;
                img {
                    margin-right: 10px;
                }
            }
        }
    }
}
</style>