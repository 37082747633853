<template>
    <div>
        <vue-breadcrumbs :breadCrumbs='breadCrumbs'/>
        <div class="vue_card">
            <booking-form @submitHandler='submitHandler' :limit='limit.length'/>
            <div v-if="ready"  class="vue_card__info">
                <div v-for="(item, idx) in cardDetails" :key="item.id" class="single_box">
                    <div class="locations">
                        <p>{{idx==1 ? `${item.hotel_name} (${item.from_location.name})` : item.from_location.name}} - {{idx==0 ? `${item.hotel_name} (${item.to_location.name})` : item.to_location.name}}</p>
                    </div>
                    <passengers v-for="item, index in item.passengers" :key="index" :item='item' :index='index'/>
                    <div class="from_to">
                        <div class="from_to__box">
                            <p class="from_to__box__title">Отправление</p>
                            <div class="from_to__box__date">
                                <v-icon color="primary">mdi-calendar</v-icon>
                                <div>
                                    <p>
                                        {{idx==1 ? item.hotel_name : item.from_location.name}}
                                    </p>
                                    <p>{{ $moment(item.check_out_time*1000).format('DD - MMM, YYYY') }}</p>
                                    <p>{{ $moment(item.check_out_time*1000).format('HH:mm') }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="from_to__box">
                            <p class="from_to__box__title">Прибытие</p>
                            <div class="from_to__box__date">
                                <v-icon color="primary">mdi-calendar</v-icon>
                                <div>
                                    <p>
                                        {{idx==0 ? item.hotel_name  : item.to_location.name}}
                                    </p>
                                    <p>{{ $moment(item.arrival_time*1000).format('DD - MMM, YYYY') }}</p>
                                    <p>{{ $moment(item.arrival_time*1000).format('HH:mm') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <booking-card-people :people='item' class="my-3"/>
                </div>
                <!-- <div>
                    <p>
                        <img style="vertical-align:bottom" src="@/assets/images/ui/user.svg" />
                        {{ peopleRead() }}
                    </p>
                </div> -->
                <div class="total_sum">
                    <p>Итого туда и обратно</p>
                    <p>{{Number(this.cardDetails[0].price) + Number(this.cardDetails[1].price)}} KZT</p>
                </div>
            </div>
            
        </div>
        <vue-booking-modal :dialog='dialog' :exept="'Экскурсии'" @closeDialog='closeDialog'/>
    </div>
</template>

<script>
import VueBreadcrumbs from '@/components/ui/vueBreadcrumbs.vue'
import VueAvatar from '@/components/ui/vueAvatar.vue'
import BookingCardPeople from '@/components/booking/bookingCardPeople.vue'
import BookingForm from '@/components/booking/bookingForm.vue'
import VueBookingModal from '@/components/ui/vueBookingModal.vue'
import { busesService } from '@/services/buses.service.js'
import { setModel } from '@/utils/formatMask.js'
import Passengers from '@/components/buses/passengers.vue'
export default {
    components: {
        VueBreadcrumbs,
        VueAvatar,
        BookingCardPeople,
        BookingForm,
        VueBookingModal,
        Passengers
    },
    data: () => ({
        ready: false,
        dialog: false,
        cardDetails: [],
        peopleCount: [],
        passengers: [],
        hotel: {}
    }),
    computed: {
        breadCrumbs() {
            let firstQuery = {
                adult: this.$route.query.adult,
                child: this.$route.query.child,
                pensioner: this.$route.query.pensioner,
                from_date: this.$route.query.from_date,
                to_date: this.$route.query.to_date,
                from_location: this.$route.query.from_location,
                to_location: this.$route.query.to_location,
            }
            return [
                {
                    text: 'Бронирование автобусов',
                    to: `/booking/buses/`,
                    query: { ...firstQuery, hotel: this.$route.query.hotel }
                },
                {
                    text: 'Выбор места',
                    to: `/booking/buses/rt/${this.$route.params.id}`,
                    query: { ...firstQuery, hotel: this.$route.query.hotel }
                },
                {
                    text: 'Обратно',
                    to: `/booking/buses/rt/${this.$route.params.id}/search/`,
                    query: { ...firstQuery, hotel: this.$route.query.hotel }
                },
                {
                    text: 'Выбор места',
                    to: `/booking/buses/rt/${this.$route.params.id}/search/${this.$route.params.second}`,
                    query: {...firstQuery, hotel: this.$route.query.hotel}
                },
                {
                    text: 'Бронирование',
                    to: `/booking/tours`,
                },
            ]
        },
        limit() {
            const { adult, child, pensioner } = this.$route.query
            const arr = []
            for(let i = 0; i < adult; i++) {
                arr.push('adult')
            }
            for(let i = 0; i < child; i++) {
                arr.push('child')
            }
            for(let i = 0; i < pensioner; i++) {
                arr.push('pensioner')
            }
            return arr
        },
    },
    created() {
        this.getDetails()
    },
    methods: {
        peopleRead() {
            let adult = this.$route.query.adult;
            let child = this.$route.query.child;
            let pensioner = this.$route.query.pensioner;
            let a = "", b = "", c = "";
            if (adult == 1) a = adult + " взрослый";
            else a = adult + " взрослых";

            if(child == 1) b = '1 ребенок'
            else if(child > 1 && child < 5) b = child + ' ребенка'
            else b = child + ' ребенков'

            if(pensioner == 1) c = '1 пенсионер'
            else if(pensioner > 1 && pensioner < 5) c = pensioner + ' пенсионера'
            else c = pensioner + ' пенсионеров'

            return `${a}, ${b}, ${c}`;
        },
        setHotel() {
            // this.cardDetails[0].hotel = this.cardDetails[0].bus_hotels.find(el => el.id == this.$route.query.hotel)
            // this.cardDetails[1].hotel = this.cardDetails[1].bus_hotels.find(el => el.id == this.$route.query.second_hotel)
        },
        setPassengers() {
            let seats =  this.$route.query.seats.split(',')
            for (let i = 0; i < seats.length; i++) {
                this.cardDetails[0].passengers.push({
                    seat: this.cardDetails[0].bus.bus_seat_places.find(el => el.id == seats[i]).name,
                    type: this.cardDetails[0].bus.bus_type,
                    number: this.cardDetails[0].bus.number
                })
            }
            let second_seats =  this.$route.query.second_seats.split(',')
            for (let j = 0; j < second_seats.length; j++) {
                this.cardDetails[1].passengers.push({
                    seat: this.cardDetails[1].bus.bus_seat_places.find(el => el.id == second_seats[j]).name,
                    type: this.cardDetails[1].bus.bus_type,
                    number: this.cardDetails[1].bus.number
                })
            }
        },
        async getDetails() {
            try {
                this.$loading(true)
                const { adult, child, pensioner, hotel } = this.$route.query
                let seat_1 = this.$route.query.seats
                let seat_2 = this.$route.query.second_seats
                const res1 = await busesService.getBusCard({id: this.$route.params.id, adult, child, pensioner, hotel, is_rt: true, is_back: false, seat_places: seat_1 })
                const res2 = await busesService.getBusCard({id: this.$route.params.second, adult, child, pensioner, hotel, is_rt: true, is_back: true, seat_places: seat_2})
                this.cardDetails = [res1, res2]
                this.cardDetails.forEach(e => {
                    e.is_rt = true
                })
                console.log(this.cardDetails);
                this.cardDetails.forEach(el => el.passengers = [])
                this.setPassengers()
                // this.setHotel()
                this.ready = true
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        closeDialog() {
            this.dialog = false
            this.$router.push('/booking/residence')
        },
        async allPost(data){
            const formData = this.$copy(data)
            formData.user.forEach(element => {
                setModel(element, 'phone')
            });
            const bus_order_clients = []
            const bus_order_clients_2 = []
            formData.user.forEach(el => {
                bus_order_clients.push({...el})
                bus_order_clients_2.push({...el})
            })
            const diff = this.limit.length - formData.user.length
            for (let j = 0; j < diff; j++) {
                bus_order_clients.push(this.$copy(bus_order_clients[0]))
                bus_order_clients_2.push(this.$copy(bus_order_clients[0]))
            }
            bus_order_clients.forEach((user, index) => {
                user.seat_place = Number(this.$route.query.seats.split(',')[index])
            })
            bus_order_clients_2.forEach((user, index) => {
                user.seat_place = Number(this.$route.query.second_seats.split(',')[index])
            })
            this.limit.forEach((tariff, index) => {
                bus_order_clients[index].tariff = tariff
                bus_order_clients_2[index].tariff = tariff
            })
            await busesService.postBusOrders({
                order: [
                    {
                        seal_channel: formData.seal_channel,
                        route: this.$route.params.id,
                        is_rt: true,
                        hotel: this.$route.query.hotel,
                        bus_order_clients: bus_order_clients,
                    },
                    {
                        seal_channel: formData.seal_channel,
                        route: this.$route.params.second,
                        is_rt: true,
                        hotel: this.$route.query.hotel,
                        bus_order_clients: bus_order_clients_2,
                    }
                ]
            })
        },
        async submitHandler(data) {
            try {
                this.$loading(true)
                await this.allPost(data)
                this.dialog = true
            } catch (e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.vue_card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    grid-column-gap: 20px;
    &__info {
        background: #FFFFFF;
        box-shadow: 0px 10px 20px rgba(146, 178, 193, 0.3);
        border-radius: 5px;
        padding: 20px;
        .locations {
            text-align: center;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #021011;
            p {
                margin-bottom: 0;
            }
        }
    }
}
.single_box {
    &:last-of-type {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid #94A3AD;
    }
}
.from_to {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 30px;
    &__box {
        p {
            margin-bottom: 0;
        }
        &__title {
            font-size: 12px;
            line-height: 14px;
            color: #FF8413;
            margin-left: 34px;
        }
        &__date {
            margin-top: 5px;
            display: grid;
            grid-template-columns: 24px 1fr;
            grid-column-gap: 10px;
            align-items: start;
            p {
                font-size: 16px;
                line-height: 19px;
                color: #021011;
                margin-bottom: 5px;
            }
        }
    }
}
.total_sum {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
    }
}
</style>