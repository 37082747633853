<template>
  <div>
    <div v-if="Number(people.adult_price || people.is_rt ? people.adult_rt_ticket : people.adult_ticket) * Number($route.query.adult)" class="people_count">
        <div>
            <v-icon color="primary">mdi-account</v-icon>
            <p>{{$route.query.adult}} взрослых</p>
        </div>
        <p class="price">{{(people.adult_price || people.is_rt ? people.adult_rt_ticket : people.adult_ticket) * Number($route.query.adult)}} KZT</p>
    </div>
    <div v-if="Number(people.child_price || people.is_rt ? people.child_rt_ticket : people.child_ticket ) * Number($route.query.child)" class="people_count">
        <div>
            <v-icon color="primary">mdi-account</v-icon>
            <p>{{$route.query.child}} детских</p>
        </div>
        <p class="price">{{(people.child_price || people.is_rt ? people.child_rt_ticket : people.child_ticket) * Number($route.query.child)}} KZT</p>
    </div>
    <div v-if="Number(people.is_rt ? people.pensioner_rt_ticket : people.pensioner_ticket) * Number($route.query.pensioner)" class="people_count">
        <div>
            <v-icon color="primary">mdi-account</v-icon>
            <p>{{$route.query.pensioner }} пенсионера</p>
        </div>
        <p class="price">{{people.is_rt ? +people.pensioner_rt_ticket : +people.pensioner_ticket * Number($route.query.pensioner)}} KZT</p>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        people: Object
    }
}
</script>

<style lang="scss" scoped>
.people_count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    p {
        margin-bottom: 0;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        p {
            margin-left: 12px;
        }
    }
}
</style>